<template>
  <b-nav
    tabs
    justified
  >

    <b-nav-item
      :active="$route.name === 'ReportsOrder'"
      :to="{name: 'ReportsOrder'}"
    >
      <feather-icon
        icon="TrendingUpIcon"
      /> Laporan Penjualan
    </b-nav-item>

    <b-nav-item
      :active="$route.name === 'ReportsProduct'"
      :to="{name: 'ReportsProduct'}"
    >
      <feather-icon
        icon="ArchiveIcon"
      /> Laporan Stock
    </b-nav-item>

  </b-nav>
</template>

<script>
import {
  BNav, BNavItem, BImg, BSpinner,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'reportsNav',
  components: {
    BNav,
    BNavItem,
    BImg,
    BSpinner,
  },
  setup() {
    return {
    }
  },
})
</script>

<style lang="scss" scoped>
</style>
