<template>
  <b-row>
    <b-col>
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col
              lg="12"
              md="12"
              sm="12"
            >
              <!--- navigation menu -->
              <ReportsNav />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col
      lg="12"
      md="12"
      sm="12"
    >
      <transition
        :name="routerTransition"
        mode="out-in"
      >
        <router-view />
      </transition>
    </b-col>
  </b-row>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import {
  BCard, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import ReportsNav from './components/ReportsNav.vue'

export default defineComponent({
  name: 'Orders',
  components: {
    ReportsNav,
    BCard,
    BCardBody,
    BRow,
    BCol,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
})
</script>

<style lang="scss">
</style>
